import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './shared/user/user.service';
import { User } from './shared/user/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    user$: Observable<User>;
    isLogin: boolean;
    code:any;
    state: any;

    constructor(private userService: UserService, private router: Router) {
        let urlParametros = window.location.href.toString().split('/?code=')[1];
        
        if (urlParametros) {
            let params = {
                code: '',
                state: ''
            };
            this.code = urlParametros.split('&state=')[0];
            this.state = urlParametros.split('&state=')[1];
            params.code = this.code;
            params.state = this.state;
            localStorage.setItem('integracaoParams', JSON.stringify(params))
            this.router.navigate([`/integracao`]);
            this.isLogin = true;
        }
        let previewRoute = '';
        this.user$ = userService.getUser();
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (
                    this.isLogged &&
                    this.isLogin &&
                    previewRoute === '/painel-contribuinte'
                ) {
                    this.router.navigate(['/painel-contribuinte']);
                }
                previewRoute = event.urlAfterRedirects;
            }
        });
    }

    onActivate() {
        window.scroll(0, 0);
        this.isLogin = this.isLoginPage();
    }

    private isLogged(): boolean {
        const token = localStorage.getItem('authToken');
        return token ? true : false;
    }

    private isLoginPage(): boolean {
        const loginPages = [
            '/acesso',
            '/duvidas-frequentes',
            '/login',
            '/login-certificado',
            '/recuperacao-senha',
            '/loading',
            '/integracao'
        ];
        
        return loginPages.includes(this.router.url);
    }
}
