import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";

import { Filtro } from "src/app/shared/interface/filtro";
import { DatePickerComponent, IDatePickerConfig } from "ng2-date-picker";
import * as dayjs from "dayjs";
import "dayjs/locale/pt-br";

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroComponent implements OnChanges {
  @ViewChild('datePicker') datePicker: DatePickerComponent;

  datePickerConfig: IDatePickerConfig = {
    disableKeypress: true,
    drops: 'down',
    format: 'DD/MM/YYYY'
  }

  @Output() filtrar = new EventEmitter();
  @Output() resetar = new EventEmitter();
  @Output() alterarQuantidade = new EventEmitter();
  @Output() alterarLista = new EventEmitter();

  @Input() opcoesFiltro: Array<Filtro>;
  @Input() listaDropdown: any;
  @Input() listaDropdown2: any;
  @Input() listaDropdown3: any;
  @Input() listaDropdown4: any;
  @Input() filtroQuantidade: boolean = false;
  @Input() filtroExterno: any = {};
  @Input() datInicioMaxima: any;
  @Input() datFimMaxima: any;
  @Input() filtroSalvo: any;

  filtro: string = '';
  tipoFiltro: string;
  filtroSelecionado: Filtro;
  filtroAtivo: boolean = false;
  valorFiltro: any = '';
  datInicio: any = null;
  datFim: any = null;
  tamanhoPagina: number = 10;
  
  constructor() {
    dayjs.locale('pt-br');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtroExterno?.currentValue['ativo']) {
      this.filtro = this.filtroExterno['indexOpcao'];
      this.tipoFiltro = this.opcoesFiltro[this.filtroExterno['indexOpcao']].tipo;
      this.valorFiltro = this.filtroExterno['valor'];
      this.filtroAtivo = true;
      this.atualizarFiltroExterno();
    }

    if(changes.filtroSalvo?.currentValue['param'] && 
      (changes.filtroSalvo?.currentValue['valor'] || changes.filtroSalvo?.currentValue?.datInicio || changes.filtroSalvo?.currentValue?.datFim)) 
    {
      const indexFiltro = this.opcoesFiltro.findIndex(res => res.param == changes.filtroSalvo?.currentValue['param']).toString();
      this.tipoFiltro = this.opcoesFiltro[indexFiltro].tipo;
      this.filtro = indexFiltro; 
      this.filtroSelecionado = this.opcoesFiltro[indexFiltro];
      this.valorFiltro = changes.filtroSalvo?.currentValue['valor'];
      this.filtroAtivo = true;
      
      if(this.tipoFiltro == 'periodo') {
        this.datInicio = dayjs(changes.filtroSalvo?.currentValue?.datInicio);
        this.datFim = dayjs(changes.filtroSalvo?.currentValue?.datFim);
      }
    }
  }

  getTipoFiltro(event) {
    this.filtroSelecionado = this.opcoesFiltro[event.target.value];
    this.tipoFiltro = this.opcoesFiltro[event.target.value].tipo;
    this.valorFiltro = '';
    this.datInicio = null;
    this.datFim = null;
  
    if (this.filtroAtivo) {
      this.resetFiltro(true);
    }

    if (this.opcoesFiltro[event.target.value].tipo == 'dropdown') {
      this.alterarLista.emit(event);
    }
  }
  

  getDataInicio(event) {
    this.datInicio = event;
  }

  getDataFim(event) {
    this.datFim = event;
  }

  alterarTamanhoPagina() {
    this.alterarQuantidade.emit(this.tamanhoPagina);
  }

  filtrarDados() {
    this.filtroAtivo = true;
    let obj: any;
    if (this.tipoFiltro != 'periodo') {
      obj = {
        param: this.filtroSelecionado.param,
        valor: this.valorFiltro
      }
    } else {
      obj = {
        param: this.filtroSelecionado.param,
        datFim: this.datFim,
        datInicio: this.datInicio
      }
    }
    this.filtrar.emit(obj);
  }

  resetFiltro(onChange?: boolean) {
    if (onChange) {
      this.filtroAtivo = false;
      this.valorFiltro = '';
    } else {
      this.filtroAtivo = false;
      this.filtro = '';
      this.valorFiltro = '';
      this.tipoFiltro = '';
    }
    this.resetar.emit();
  }

  limparFiltro() {
    this.filtro = '';
    this.tipoFiltro = '';
    this.valorFiltro = '';
    this.datInicio = null;
    this.datFim = null;
    this.filtroAtivo = false;
  }

  atualizarFiltroExterno() {
    if (this.filtroExterno.ativo) {
      const indexOpcao = this.filtroExterno.indexOpcao;
      this.filtro = indexOpcao.toString();
      this.valorFiltro = this.listaDropdown3.find(item => item.descricao === this.filtroExterno.valor)?.codigo || '';
    }
  }

}