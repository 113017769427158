import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../shared/user/user.service';
import { MENUS } from '../../shared/menu/menu.mock';

@Injectable({
  providedIn: 'root'
})
export class MenuGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    const menusPerfil = this.userService.getUserMenu();
    const menusPermitidos = MENUS.filter(menu => menusPerfil.some(menuPerfil => menuPerfil.codigo === menu.id));
    const indexMenuAtual = menusPermitidos.findIndex(menu => menu.url === route.routeConfig?.path);

    if (indexMenuAtual === -1) {
      const nextMenu = menusPermitidos[indexMenuAtual + 1];
      if (nextMenu) {
        this.router.navigate([nextMenu.url]);
      }
      return false;
    }

    return true;
  }
}
