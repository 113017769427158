<div class="container">
  <div class="container-breadcrumb mt-5">
    <a>
      <app-icone (click)="navegarParaAcesso()"></app-icone>
       / 
    </a>
      <b>Dúvidas Frequentes</b>
  </div>
  <div class="container-conteudo">
    <h1 class="text-center duvidas"> Dúvidas Frequentes </h1>
    <section class="texto mt-5">
      <div class="pergunta">
        <div class="titulo-container">
          <img src="assets/images/seta-para-baixo.png" width="25px" height="25px" class="seta">
          <span class="titulo">O que é o portal SFERA?</span>
        </div>
        <div class="resposta">
          É um portal destinado a auxiliar os órgãos de fiscalização na orientação dos contribuintes, no acompanhamento prático das obrigações fiscais.
        </div>
      </div>
      <div class="pergunta">
        <div class="titulo-container">
          <img src="assets/images/seta-para-baixo.png" width="25px" height="25px" class="seta">
          <span class="titulo">Como acessar o SFERA?</span>
        </div>
        <div class="resposta">
          O acesso é utilizando sua conta Gov.br. 
          <a href="https://sfera-ged.sefaz.to.gov.br/share/s/uptl0VMJRhu9ujcQUFYPbw" target="_blank">Assista ao vídeo explicativo</a>.
        </div>
      </div>
    </section>
  </div>
</div>
