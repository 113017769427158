<app-conteudo titulo="Histórico de Justificativas">
    <div class="main-body">
       <div class="page-wrapper">
          <div class="card">
             <div class="d-flex card-header">
                <h5 class="mr-auto">Filtro de Pesquisa</h5>
             </div>
             <div class="card-body">
                <form [formGroup]="formCiencia">
                <div class="form-group row">
                   <div class="col-md-6">
                      <label for="validacao" class="col-form-label">
                      Por data da emissão da Justificativa
                      </label>
                      <app-datepicker-interval
                      [id]="'searchDatePicker'"
                      (range)="setRange($event)">
                      </app-datepicker-interval>
                      <div
                         *ngIf="
                         formCiencia.controls.data
                         .invalid &&
                         (formCiencia.controls.data
                         .dirty ||
                         formCiencia.controls.data
                         .touched)
                         "
                         class="invalid-feedback d-block"
                         >
                         <div
                            *ngIf="
                            formCiencia.controls.data
                            .errors.required
                            "
                            > 
                            O campo é obrigatório.
                         </div>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <label 
                         class="col-form-label" 
                         for="validacao">
                      Origem
                      </label>
                      <select
                         class="form-control form-control-sm"
                         formControlName="origem">
                         <option value="todos"> Todos </option>
                         <option value="rejeicao-pam">
                            Rejeição de Pam
                         </option>
                         <option value="rejeicao-medida">
                            Rejeição de Solicitação de Medida de Gestão
                         </option>
                         <option value="remocao-auditor">
                            Remoção de Auditor(res) para gerar PAM
                         </option>
                         <option value="rejeicao-designacao">
                            Rejeição de Designação pelo Agente Fiscal
                         </option>
                         <option value="alteracao-providencia">
                            Alteração de Providência do Monitoramento Virtual
                         </option>
                         <option value="fora-do-prazo">
                            Ciência fora do prazo de validade do documento
                         </option>
                         <option value="alteracao-distribuicao">
                            Alteração Distribuição do PAM pelo Supervisor
                         </option>
                         <option value="monitoramento-vetar-empresa">
                            Monitoramento - Vetar Empresa
                         </option>
                         <option value="monitoramento-anular-empresa">
                            Monitoramento - Anular Empresa
                         </option>
                         <option value="anular-empresa-canc-mandado">
                            Anular empresa cancelando mandado em projetos vencidos
                         </option>
                         <option value="comunicado-fora-horario">
                            Enviar comunicação fora do horário permitido
                         </option>
                      </select>
                   </div>
                </div>
                <div class="form-group row">
                   <div class="col-md-2">
                      <label for="validacao" class="col-form-label"
                         >
                      Servidor/Matrícula
                      </label> 
                      <input
                         formControlName="servidor"
                         type="text"
                         class="form-control form-control-sm"
                         id="mmf"
                         />
                      <div
                         *ngIf="
                         formCiencia.controls.servidor
                         .invalid &&
                         (formCiencia.controls.servidor
                         .dirty ||
                         formCiencia.controls.servidor
                         .touched)
                         "
                         class="invalid-feedback d-block"
                         >
                         <div
                            *ngIf="
                            formCiencia.controls.servidor
                            .errors.required
                            "
                            > 
                            O campo é obrigatório.
                         </div>
                      </div>
                   </div>
                   <div class="col-md-4">
                      <label for="validacao" class="col-form-label"
                         >Projeto</label>
                      <select
                         class="form-control form-control-sm"
                         formControlName="projeto">
                         <option value="todos"> Todos </option>
                         <option value="projeto-1">
                            Projeto 1
                         </option>
                         <option value="projeto-2">
                            Projeto 2 
                         </option>
                         <option value="projeto-3">
                            Projeto 3
                         </option>
                         <option value="projeto-4">
                            Projeto 4
                         </option>
                      </select>
                   </div>
                   <div class="col-md-3">
                      <label for="validacao" class="col-form-label">
                        Inscrição Estadual
                      </label>
                      <input
                         type="text"
                         formControlName="inscricaoEstadual"
                         class="form-control form-control-sm"
                         id="inscricaoEstadual"
                         mask="00.000.000-0"
                         /> 
                      <div
                         *ngIf="
                         formCiencia.controls.inscricaoEstadual
                         .invalid &&
                         (formCiencia.controls.inscricaoEstadual
                         .dirty ||
                         formCiencia.controls.inscricaoEstadual
                         .touched)
                         "
                         class="invalid-feedback d-block"
                         >
                         <div
                            *ngIf="
                            formCiencia.controls.inscricaoEstadual
                            .errors.required
                            "
                            > 
                            O campo obrigatório.
                         </div>
                      </div>
                   </div>
                   <div class="col-md-3">
                      <label for="validacao" class="col-form-label"
                         >
                      PAM
                      </label>
                      <input
                         type="text"
                         formControlName="pam"
                         class="form-control form-control-sm"
                         id="pam"
                         />
                      <div
                         *ngIf="
                         formCiencia.controls.pam
                         .invalid &&
                         (formCiencia.controls.pam
                         .dirty ||
                         formCiencia.controls.pam
                         .touched)
                         "
                         class="invalid-feedback d-block"
                         >
                         <div
                            *ngIf="
                            formCiencia.controls.pam
                            .errors.required
                            "
                            > 
                            O campo PAM é obrigatório.
                         </div>
                      </div>
                   </div>
                </div>
                <div class="form-group row">
                   <div class="col-md-8"></div>
                   <div class="col-md-2">
                      <button
                         type="submit"
                         (click)="validacao()"
                         class="p-0 btn btn-success ml-2 form-control form-control-sm">
                      <span>Consultar</span>
                      </button>
                   </div>
                   <div class="col-md-2">
                      <button
                         class="p-0 btn btn-success ml-2 form-control form-control-sm">
                      <span>Listar Tudo</span>
                      </button>
                   </div>
                </div>
                </form>
             </div>
          </div>
          <div class="card">
             <div class="card-body">
                <app-tabela
                (openOptions)="setContributor($event)"
                [dados]="monitoramento.dados"
                [campos]="monitoramento.campos"
                [calcColumn]="'all'"
                ></app-tabela>
             </div>
          </div>
          <div class="card" *ngIf="contributorId !=''">
             <div class="d-flex card-header">
                <h5 class="mr-auto">Filtrar</h5>
             </div>
             <div class="card-body">
                <form>
                   <div class="form-group row">
                      <div class="custom-control custom-radio custom-control-inline mt-1">
                         <input
                            value="inscricaoEstadual"
                            type="radio"
                            id="cfg"
                            name="projetosTipos"
                            class="custom-control-input"
                            />
                         <label class="custom-control-label" for="cfg"
                            >Inscrição Estadual</label>
                      </div>
                      <div
                         class="custom-control custom-radio custom-control-inline mt-1">
                         <input
                            value="cnpj-basico"
                            type="radio"
                            id="cnpj-basico"
                            name="projetosTipos"
                            class="custom-control-input"
                            />
                         <label
                            class="custom-control-label"
                            for="cnpj-basico"
                            >CNPJ Básico</label>
                      </div>
                      <div
                         class="custom-control custom-radio custom-control-inline mt-1">
                         <input
                            formControlName="projetosTipos"
                            value="razao-social"
                            type="radio"
                            id="razao-social"
                            name="projetosTipos"
                            class="custom-control-input"
                            />
                         <label
                            class="custom-control-label"
                            for="razao-social"
                            >Razão Social</label>
                      </div>
                      <div
                         class="custom-control custom-radio custom-control-inline mt-1">
                         <input
                            formControlName="projetosTipos"
                            value="nome-fantasia"
                            type="radio"
                            id="nome-fantasia"
                            name="projetosTipos"
                            class="custom-control-input"/>
                         <label
                            class="custom-control-label"
                            for="nome-fantasia"
                            >Nome Fantasia
                         </label>
                      </div>
                      <div
                         class="custom-control custom-radio custom-control-inline mt-1">
                         <input
                            class="custom-control-input"
                            value="cnpj"
                            type="radio"
                            id="cnpj"
                            name="projetosTipos"
                            />
                         <label class="custom-control-label" for="cnpj"
                            >CNPJ
                         </label>
                      </div>
                      <label for="filter" class="mx-3 col-form-label" style="margin-top:-2px;">
                      St. Monitoramento Fiscal:
                      </label>
                      <select
                         class="col-sm-2 form-control form-control-sm"
                         >
                         <option value="todos"> Todos </option>
                         <option value="">
                            Planejada
                         </option>
                         <option value="">
                            Designada
                         </option>
                         <option value="">
                            Devolvida
                         </option>
                         <option value="">
                            Em Execução
                         </option>
                         <option value="">
                            Vetada
                         </option>
                         <option value="">
                            Anulada
                         </option>
                         <option value="">
                            Vencida
                         </option>
                         <option value="">
                            Concluída
                         </option>
                         <option value="">
                            Pendente
                         </option>
                         <option value="">
                            Em Aberto
                         </option>
                         <option value="">
                            Homologada
                         </option>
                      </select>
                      <button
                         type="submit"
                         class="p-0 btn btn-success ml-2 form-control col-sm-2 form-control-sm">
                      <span>Buscar</span>
                      </button>
                   </div>
                </form>
             </div>
          </div>
          <div class="card" *ngIf="contributorId !=''">
             <div class="d-flex card-header">
                <h5 class="mr-auto">{{contributorId}}</h5>
             </div>
             <div class="card-body">
                <app-tabela
                [dados]="monitoramentoEmpresa.dados"
                [campos]="monitoramentoEmpresa.campos"
                [calcColumn]="'all'"
                ></app-tabela>
             </div>
          </div>
          <div class="card" *ngIf="contributorId !=''">
             <div class="d-flex card-header">
                <h5 class="mr-auto">
                   Legenda da Situação da Empresa no Monitoramento Fiscal
                </h5>
             </div>
             <div class="row p-2" footer>
                <div class="d-flex justify-content-center col-12">
                   <div class="col-sm">
                      <i class="circle-planejada"></i>
                      Planejada
                   </div>
                   <div class="col-sm">
                      <i class="circle-homologada"></i>
                      Homologada
                   </div>
                   <div class="col-sm">
                      <i class="circle-pendente"></i>
                      Pendente
                   </div>
                   <div class="col-sm">
                      <i class="circle-designada"></i>
                      Designada
                   </div>
                   <div class="col-sm">
                      <i class="circle-vetada"></i>
                      Vetada
                   </div>
                   <div class="col-sm">
                      <i class="circle-anulada"></i>
                      Anulada
                   </div>
                   <div class="col-sm">
                      <i class="circle-devolvida"></i>
                      Devolvida
                   </div>
                   <div class="col-sm">
                      <i class="circle-emAberto"></i>
                      Em aberto
                   </div>
                   <div class="col-sm">
                      <i class="circle-emExecucao"></i>
                      Em execução
                   </div>
                   <div class="col-sm">
                      <i class="circle-vencida"></i>
                      Vencida
                   </div>
                   <div class="col-sm">
                      <i class="circle-concluida"></i>
                      Concluida
                   </div>
                   <div class="col-sm">
                      <i (click)="help2()" class="feather icon-help-circle ml-3"></i>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </app-conteudo>
 <app-modal id="comunicadoModal" titulo="Gerar Comunicação" tamanho="lg">
    <div class="container">
       <form>
          <div class="form-row">
             <div class="form-group col-12 mt-2">
                <label for="para">Para:</label>
                <div class="row">
                   <div class="d-flex col-md-4">
                      <input
                         type="text"
                         class="form-control form-control-sm"
                         id="para-matricula"
                         placeholder="Matrícula"
                         />
                   </div>
                   <div class="d-flex col-md-8">
                      <input
                         type="text"
                         class="form-control form-control-sm"
                         id="para-nome"
                         placeholder="Nome"
                         />
                      <i
                         (click)="pesquisarMatriculaNome()"
                         class="feather icon-search input-group-text ml-2"
                         ></i>
                   </div>
                </div>
             </div>
          </div>
          <div class="form-row">
             <div class="form-group col-12">
                <label for="assunto">Assunto:</label>
                <div class="d-flex">
                   <input
                      type="text"
                      class="form-control form-control-sm"
                      id="assunto"
                      />
                </div>
             </div>
          </div>
          <div class="form-row">
             <div class="col-12">
                <label for="texto">Texto:</label>
                <textarea
                   class="form-control"
                   id="textarea"
                   rows="3"
                   minlength="15"
                   maxlength="255"
                   ></textarea>
             </div>
          </div>
          <button
             type="submit"
             class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-0"
             >
          <span>Enviar</span>
          </button>
          <button
             type="button"
             (click)= "fechaModalComunicado()"
             class="p-0 btn btn-secondary form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-2"
             >
          <span>Cancelar</span>
          </button>
       </form>
    </div>
 </app-modal>
 <app-modal
    (close)="gerarComunicado()"
    id="matriculaNomeModal"
    titulo="Pesquisa Matrícula/Nome"
    tamanho="xg"
    >
    <div class="container">
       <form
          >
          <div class="form-row align-items-end mt-2 mb-2">
             <div class="form-group mb-0 col-4 mt-2">
                <label for="matricula">Matrícula:</label>
                <div class="d-flex">
                   <input
                      type="text"
                      class="form-control form-control-sm"
                      id="matricula"
                      />
                </div>
             </div>
             <div class="form-group mb-0 col-4 mt-2">
                <label for="nome">Nome:</label>
                <div class="d-flex">
                   <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nome"
                      />
                </div>
             </div>
             <button
                type="submit"
                class="p-0 btn btn-success form-control col-sm-2 form-control-sm mb-0 ml-3"
                >
             <span>Pesquisar</span>
             </button>
          </div>
          <div class="row">
             <div class="col-12">
                <app-tabela
                [dados]="matriculas.dados"
                [campos]="matriculas.campos"
                ></app-tabela>
             </div>
          </div>
          <button
             disabled
             type="button"
             class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 mb-0 mr-0 float-right"
             >
          <span>Selecionar</span>
          </button>
       </form>
    </div>
 </app-modal>