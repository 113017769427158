import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';

import { LoginComponent } from './pages/login/login.component';
import { LoginModule } from './pages/login/login.module';
import { LoginGuard } from './pages/login/login.guard';

import { LoadingComponent } from './pages/loading/loading.component';
import { LoadingModule } from './pages/loading/loading.module';
import { LoadingGuard } from './pages/loading/loading.guard';

import { NaoEncontradoComponent } from './pages/nao-encontrado/nao-encontrado.component';
import { NaoEncontradoModule } from './pages/nao-encontrado/nao-encontrado.module';

import { RecuperacaoSenhaComponent } from './pages/recuperacao-senha/recuperacao-senha.component';
import { RecuperacaoSenhaModule } from './pages/recuperacao-senha/recuperacao-senha.module';

import { AcessoComponent } from './pages/acesso/acesso.component';
import { AcessoModule } from './pages/acesso/acesso.module';
import { AuthGuard } from './core/auth/auth.guard';

import { LoginCertificadoComponent } from './pages/login-certificado/login-certificado.component';
import { LoginCertificadoModule } from './pages/login-certificado/login-certificado.module';
import { AgenteFiscalComponent } from './pages/agente-fiscal/agente-fiscal.component';

import { OrientadorSupervisorComponent } from './pages/orientador-supervisor/orientador-supervisor.component';
import { VisualizarComponent } from './pages/orientador-supervisor/visualizar/visualizar.component';
import { CatriCoordenadorComponent } from './pages/catri-coordenador/catri-coordenador.component';
import { CatriCepacComponent } from './pages/catri-cepac/catri-cepac.component';
import { CorexCoordenadorComponent } from './pages/corex-coordenador/corex-coordenador.component';
import { MandadoETermosComponent } from './pages/mandado-e-termos/mandado-e-termos.component';
import { HistoricoDeJustificativasComponent } from './pages/historico-de-justificativas/historico-de-justificativas.component';
import { SolicitacaoComponent } from './pages/solicitacao-de-medidas-de-gestao/solicitacao-de-medidas-de-gestao.component';
import { NgxMaskModule } from 'ngx-mask';
import { IntegracaoComponent } from './pages/integracao/integracao.component';
import { IntegracaoGuard } from './pages/integracao/integracao.guard';
import { DuvidasFrequentesComponent } from './pages/acesso/duvidas-frequentes/duvidas-frequentes.component';
import { MenuGuard } from './core/guards/menu.guard';


const routes: Routes = [

    {
        path: 'solicitacao-de-medidas-de-gestao',
        component: SolicitacaoComponent,
        data: {
            title: 'Solicitação de Medidas de Gestão'
        },
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'catri-coordenador',
        component: CatriCoordenadorComponent,
        data: {
            title: 'Catri Coordenador'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'mandado-e-termos',
        component: MandadoETermosComponent,
        data: {
            title: 'Mandado e Termos'
        },
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'corex-coordenador',
        component: CorexCoordenadorComponent,
        data: {
            title: 'Corex Coordenador'
        },
        canActivate: [AuthGuard]
    },
    {
        path: 'historico-de-justificativas',
        component: HistoricoDeJustificativasComponent,
        data: {
            title: 'Histórico de Justificativas'
        },
        canActivate: [AuthGuard, MenuGuard]
    },

    {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'painel-contribuinte',
        data: {
            title: 'Painel do Contribuinte'
        },
        loadChildren:
            () => import('./pages/painel-contribuinte/painel-contribuinte.module').then(m => m.PainelContribuinteModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'monitoramento-fiscal',
        data: {
            title: 'Monitoramento Fiscal'
        },
        loadChildren:
            () => import('./pages/monitoramento-fiscal/monitoramento-fiscal.module').then(m => m.MonitoramentoFiscalModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'monitoramento-fiscal-virtual',
        data: {
            title: 'Monitoramento Fiscal Virtual'
        },
        loadChildren:
            () => import('./pages/monitoramento-fiscal-virtual/monitoramento-fiscal-virtual.module').then(m => m.MonitoramentoFiscalVirtualModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'comunicado-eletronico',
        data: {
            title: 'Comunicação Eletrônica'
        },
        loadChildren:
            () => import('./pages/comunicado-eletronico/comunicado-eletronico.module').then(m => m.ComunicadoEletronicolModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'agente-fiscal',
        component: AgenteFiscalComponent,
        data: {
            title: 'Agente Fiscal'
        },
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'orientador-supervisor',
        data: {
            title: 'Orientador Supervisor'
        },
        loadChildren:
            () => import('./pages/orientador-supervisor/orientador-supervisor.module').then(m => m.OrientadorSupervisorModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'monitor-de-desempenho',
        data: {
            title: 'Análise de Dados'
        },
        loadChildren:
            () => import('./pages/monitor-de-desempenho/monitor-de-desempenho.module').then(m => m.MonitorDeDesempenhoModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'grm',
        data: {
            title: 'GRM - Gerenciamento do Resultado do Monitoramento Fiscal'
        },
        loadChildren:
            () => import('./pages/grm/grm.module').then(m => m.GRMModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'configuracao',
        data: {
            title: 'Configuração'
        },
        loadChildren:
            () => import('./pages/configuracao/configuracao.module').then(m => m.ConfiguracaoModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'doc-fiscais',
        data: {
            title: 'Documentos Fiscais'
        },
        loadChildren:
            () => import('./pages/doc-fiscais/doc-fiscais.module').then(m => m.DocFiscaisModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'cadastro-menu',
        data: {
            title: 'Cadastro'
        },
        loadChildren:
            () => import('./pages/cadastro-menu/cadastro-menu.module').then(m => m.CadastroMenuModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'produtor-rural-menu',
        data: {
            title: 'Produtor Rural'
        },
        loadChildren:
            () => import('./pages/produtor-rural-menu/produtor-rural-menu.module').then(m => m.ProdutorRuralModuleModule),
        canActivate: [AuthGuard, MenuGuard]
    },
    {
        path: 'catri-cepac',
        component: CatriCepacComponent,
        data: {
            title: 'CATRI/CEPAC'
        },
        canActivate: [AuthGuard]
    },

    {
        path: 'login-certificado',
        component: LoginCertificadoComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'loading',
        component: LoadingComponent,
        canActivate: [LoadingGuard]
    },
    {
        path: 'recuperacao-senha',
        component: RecuperacaoSenhaComponent
    },
    {
        path: 'integracao',
        component: IntegracaoComponent,
        canActivate: [IntegracaoGuard]
    },
    {
        path: 'acesso',
        component: AcessoComponent
    },
    {
        path: 'duvidas-frequentes',
        component: DuvidasFrequentesComponent
    },
    {
        path: '',
        redirectTo: 'painel-contribuinte',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: NaoEncontradoComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [
        RouterModule,
        DashboardModule,
        LoginModule,
        LoginCertificadoModule,
        LoadingModule,
        RecuperacaoSenhaModule,
        AcessoModule,
        NaoEncontradoModule,
        NgxMaskModule
    ]
})
export class AppRoutingModule {}
