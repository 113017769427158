export const DataTableOptions = {
    dom: 'Bfrtip',
    'columnDefs': [{
        'targets': 0,
        'searchable': false,
        'orderable': false,
        'className': 'dt-body-center',
        // 'render': function (data, type, full, meta){
        //     return '<div class="col-sm"><i class="circle-ligth-blue"></i>Aguardando Aprovação</div>';
        // }
     }],
    buttons: [
        { 
            extend: 'csv',
            className: 'btn-dt btn-siget-primary csvButton', 
            exportOptions: {
                columns: ':visible'
            } 
        },
        { 
            extend: 'excel', 
            className: 'btn-dt btn-siget-primary excelButton',
            exportOptions: {
                columns: ':visible'
            } 
        },
        {
            extend: 'pdf',
            className: 'btn-dt btn-siget-primary pdfButton',
            orientation: 'landscape',
            exportOptions: {
                columns: ':visible'
            }
        },
        {
            extend: 'colvis',
            className: 'btn-dt btn-siget-primary',
            text: 'Colunas'
        }
    ],
    rowReorder: false,
    ordering: false,
    info: false,
    searching: false,
    paginate: false,
    pageLength: '10',
    pagingType: 'full_numbers',
    lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'Todos']],
    language: {
        sEmptyTable: 'Nenhum item encontrado',
        sInfo: 'Mostrando de _START_ até _END_ de _TOTAL_ itens',
        sInfoEmpty: 'Mostrando 0 até 0 de 0 itens',
        sInfoFiltered: '(Filtrados de _MAX_ itens)',
        sInfoPostFix: '',
        sInfoThousands: '.',
        sLengthMenu: '_MENU_ por página',
        sLoadingRecords: 'Carregando...',
        sProcessing: 'Processando...',
        sZeroRecords: 'Nenhum item encontrado',
        sSearch: 'Pesquisar:',
        oPaginate: {
            sNext: 'Próximo',
            sPrevious: 'Anterior',
            sFirst: 'Primeiro',
            sLast: 'Último'
        },
        oAria: {
            sSortAscending: ': Ordenar colunas de forma ascendente',
            sSortDescending: ': Ordenar colunas de forma descendente'
        }
    }
};
