import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../../shared/user/user.service';
import { User } from '../../shared/user/user';
import { MenuService } from '../../shared/menu/menu.service';
import { ModalService } from 'src/app/shared/modal/modal.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    openMenu = true;

    user$: Observable<User>;

    constructor(
        private userService: UserService,
        private menuService: MenuService,
        private router: Router,
        private modalService: ModalService,
    ) {
        this.user$ = userService.getUser();
    }

    ngOnInit() {
        this.menuService.openMenu.subscribe(data => {
            this.openMenu = data;
        });
    }

    logout() {
        this.userService.logout();
        this.router.navigate(['acesso']);
    }

    fecharModal() {
        this.modalService.fecha('tutoriais');
    }

    abreModal(modal: string): void {
        this.modalService.abre(modal);
      }
}
