<app-conteudo titulo="Agente Fiscal">
    <div class="main-body">
        <div class="page-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="d-flex card-header">
                            <h5 class="mr-auto">Agenda</h5>
                        </div>
                        <div class="card-body">
                            <app-tabela
                                [dados]="agenda.dadosAgenda"
                                [campos]="agenda.campos"
                                (openOptions)="action($event)"
                            ></app-tabela>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="d-flex card-header">
                    <h5 class="mr-auto">Legenda</h5>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-sm">
                            <i class="feather icon-info mr-1"></i> Pendente
                            <i class="feather icon-calendar ml-3"></i> Fora do
                            Prazo
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="d-flex card-header">
                    <h5 class="mr-auto">Filtrar</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="formFiltro"
                    (ngSubmit)="filter()">
                  <div class="form-group row">
                      <div class="col-md-3">
                          <label for=""
                                 class="col-form-label">Período</label>
                          <app-datepicker-interval [id]="'searchDatePicker'"
                                                   (range)="setRange($event)">
                          </app-datepicker-interval>
                          <div
                                  *ngIf="
                                      formFiltro.controls.periodo.invalid &&
                                      (formFiltro.controls.periodo.dirty ||
                                          formFiltro.controls.periodo.touched)
                                  "
                                  class="invalid-feedback d-block"
                              >
                                  <div
                                      *ngIf="
                                          formFiltro.controls.periodo.errors
                                              .required
                                      "
                                  >
                                      Campo obrigatório.
                                  </div>
                              </div>
                          
                      </div>
                      <div class="col-md-4">
                              <label class="col-form-label"
                                     for="filter">Tipo de Projeto</label>
                      <select class="form-control form-control-sm"
                              formControlName="tipo">
                          <option value="todos"> Todos </option>
                          <option value="fiscal-padrao">
                              Monitoramento Fiscal Padrão
                          </option>
                          <option value="especial">
                              Monitoramento Especial
                          </option>
                          <option value="fiscal-comex">
                              Monitoramento Fiscal COMEX
                          </option>
                          <option value="fiscal-fdi">
                              Monitoramento Fiscal FDI
                          </option>
                          <option value="fiscal-nao-contribuinte">
                              Monitoramento Não Contribuinte
                          </option>
                      </select>

                      
                      </div>
                      <div class="custom-control custom-checkbox mx-3 d-flex align-items-end p-2">
                          <input class="form-check-input custom-control-input"
                                 type="checkbox"
                                 id="ativos"
                                />
                          <label class="form-check-label custom-control-label"
                                 for="ativos">
                              Ativos
                          </label>
                      </div>
                      <div class="col-md-2 d-flex align-items-end">
                      <button type="submit"
                              class="btn btn-success form-control form-control-sm mb-0 p-0"
                              (click)="validacaoFormulario()">

                          <span>Consultar</span>
                      </button>
                      </div>
                  </div>
              </form>
                </div>
            </div>

            <div class="card">
                <div class="d-flex card-header">
                    <h5 class="mr-auto">Monitoramento Fiscal</h5>
                </div>
                <div class="card-body">
                    <app-tabela
                        [dados]="monitoramento.dados"
                        [campos]="monitoramento.campos"
                    ></app-tabela>
                </div>
            </div>

            <!--<div class="card">
                <div class="d-flex card-header">
                    <h5 class="mr-auto">Legenda da Situação do Indicadores</h5>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-sm">
                            <i class="feather icon-alert-circle mr-1"></i>
                            Verificar Situação
                            <i class="feather icon-check-circle ml-3"></i>
                            Situação Regular
                        </div>
                    </div>
                </div>
            </div>-->

            <div class="card">
                <div class="d-flex card-header">
                    <h5 class="mr-auto">
                        Legenda da Situação da Empresa no Monitoramento Fiscal
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-sm">
                            <i class="feather icon-rotate-cw mr-1"></i>
                            Designada
                            <i class="feather icon-rotate-ccw ml-3"></i>
                            Devolvida
                            <i class="feather icon-minus-circle ml-3"></i>
                            Em Aberto
                            <i class="feather icon-play-circle ml-3"></i>
                            Em Execução
                            <i class="feather icon-calendar ml-3"></i>
                            Vencida
                            <i class="feather icon-check-circle ml-3"></i>
                            Concluída
                            <i
                                (click)="help()"
                                class="feather icon-help-circle ml-5"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-conteudo>

<app-modal id="comunicadoModal" titulo="Gerar Comunicação" tamanho="lg">
    <div class="container">
        <form >
            <div class="form-row">
                <div class="form-group col-12 mt-2">
                     <label for="para">Para:</label>
                     <div class="row">
                         <div class="d-flex col-md-4">
                             <input
                                 type="text"
                                 class="form-control form-control-sm"
                                 id="para-matricula"
                                 placeholder="Matrícula"
                             />
                         </div>


                         <div class="d-flex col-md-8">
                             <input
                                 type="text"
                                 class="form-control form-control-sm"
                                 id="para-nome"
                                 placeholder="Nome"
                             />
                             <i
                             (click)="pesquisarMatriculaNome()"
                             class="feather icon-search input-group-text ml-2"
                             ></i>
                         </div>
                     </div>
                 </div>
             </div>
             <div class="form-row">
                <div class="form-group col-12">
                     <label for="assunto">Assunto:</label>
                     <div class="d-flex">
                         <input
                             type="text"
                             class="form-control form-control-sm"
                             id="assunto"
                             required
                         />
                     </div>
                 </div>
             </div>
             <div class="form-row">
                 <div class="col-12">
                     <label for="texto">Texto:</label>
                     <textarea
                         class="form-control"
                         id="textarea"
                         rows="3"
                         minlength="15"
                         maxlength="255"
                         required
                     ></textarea>
                 </div>
             </div>
             <button
                 type="submit"
                 class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-0"
             >
                 <span>Enviar</span>
             </button>
             <button
                 type="button"
                 (click)="fecharModalComunicado()"
                 class="p-0 btn btn-secondary form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-2"
             > 
                 <span>Cancelar</span>
             </button>
         </form>
     </div>
 </app-modal>

<app-modal
    id="matriculaNomeModal"
    titulo="Pesquisa Matrícula/Nome"
    tamanho="xg"
>
    <div class="container">
        <form
            [formGroup]="formMatriculaNome"
        >
            <div class="form-row align-items-end mt-2 mb-2">
                <div class="form-group mb-0 col-4 mt-2">
                    <label for="matricula">Matrícula:</label>
                    <div class="d-flex">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="matricula"
                        />
                    </div>
                </div>

                <div class="form-group mb-0 col-4 mt-2">
                    <label for="nome">Nome:</label>
                    <div class="d-flex">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nome"
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    class="p-0 btn btn-success form-control col-sm-2 form-control-sm mb-0 ml-3"
                >
                    <span>Consultar</span>
                </button>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-tabela
                        [dados]="matriculas.dados"
                        [campos]="matriculas.campos"
                    ></app-tabela>
                </div>
            </div>
            <button
                disabled
                type="button"
                class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 mb-0 mr-0 float-right"
            >
                <span>Selecionar</span>
            </button>
        </form>
    </div>
</app-modal>
