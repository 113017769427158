<app-conteudo titulo="COREX Coordenador">
        <div class="main-body">
            <div class="page-wrapper">
                <div class="row">
    
                    <div class="col-md-8">
                        <div class="card">
                            <div class="d-flex card-header">
                                <h5 class="mr-auto">Agenda</h5>
                            </div>
                            <div class="card-body">
                                <app-tabela [dados]="agenda.dadosAgenda" [campos]="agenda.campos" (openOptions)="action($event)">
                                </app-tabela>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-4">
                        <div class="card">
                            <div class="d-flex card-header">
                                <h5 class="mr-auto">Caixa de Comunicações</h5>
                            </div>
                            <div class="card-body">
                                <ul>
                                    <li>
                                        <span class="title">
                            Projeto Homologado
                          </span>
                                        <span class="data">
                            {{
                            comunicado.projeto_homologado.projeto
                            }}
                          </span>
                                        <span class="date">
                            {{
                              comunicado.projeto_homologado.data
                            }}
                          </span>
                                    </li>
                                    <li>
                                        <span class="title">Projeto Aprovado</span>
                                        <span class="data">
                              {{
                                  comunicado.projeto_aprovado.projeto
                              }}
                          </span>
    
                                        <span class="date">
                            {{
                              comunicado.projeto_aprovado.data
                            }}
                          </span>
                                    </li>
                                    <li>
                                        <span class="title">
                            Confirmação - Leitura da
                            Comunicação
                          </span>
                                        <span class="date">
                            {{
                              comunicado.leitura
                            }}
                          </span>
                                    </li>
                                </ul>
                                <div class="actions mt-5 text-center">
                                    <button (click)="gerarComunicado()" class="btn form-control-sm p-0 px-2 btn-success mr-2">
                                        Gerar
                                    </button>
                                    <button (click)="filter()" class="btn form-control-sm p-0 px-2 btn-secondary">
                                        Atualizar
                                    </button>
                                </div>
                                <div class="alert alert-danger mt-3">
                                    As mensagens lidas são removidas da caixa de entrada em até 24 horas.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="card">
                    <div class="d-flex card-header">
                        <h5 class="mr-auto">Legenda</h5>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm">
                                <i class="feather icon-info mr-1"></i> Pendente
                                <i class="feather icon-calendar ml-3"></i> Fora do Prazo
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="card">
                    <div class="d-flex card-header">
                        <h5 class="mr-auto">Filtrar</h5>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="formFiltro" (ngSubmit)="filter()">
                            <div class="form-group row">
                                <div class="col-md-3">
                                    <label for="" class="col-form-label">Período</label>
                                    <app-datepicker-interval [id]="'searchDatePicker'" (range)="setRange($event)">
                                    </app-datepicker-interval>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label" for="filter">Tipo de Projeto</label>
                                    <select class="form-control form-control-sm" formControlName="tipo">
                                        <option value="todos"> Todos </option>
                                        <option value="fiscal-padrao">
                                            Monitoramento Fiscal Padrão
                                        </option>
                                        <option value="especial">
                                            Monitoramento Especial
                                        </option>
                                        <option value="fiscal-comex">
                                            Monitoramento Fiscal COMEX
                                        </option>
                                        <option value="fiscal-fdi">
                                            Monitoramento Fiscal FDI
                                        </option>
                                        <option value="fiscal-nao-contribuinte">
                                            Monitoramento Não Contribuinte
                                        </option>
                                    </select>
                                </div>
                                <div class="custom-control custom-checkbox mx-3 d-flex align-items-end p-2">
                                    <input class="form-check-input custom-control-input" type="checkbox" id="ativos" formControlName="projetosAtivos" />
                                    <label class="form-check-label custom-control-label" for="ativos">
                                        Ativos
                                    </label>
                                </div>
                                <div class="col-md-2 d-flex align-items-end">
                                    <button type="submit" class="btn btn-success form-control form-control-sm mb-0 p-0">
                                        <span>Consultar</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
    
                <div class="card">
                    <div class="d-flex card-header">
                        <h5 class="mr-auto">Monitoramento Projeto</h5>
                    </div>
                    <div class="card-body">
                        <app-tabela (openOptions)="setContributor($event)" [dados]="monitoramento.dados" [campos]="monitoramento.campos" [calcColumn]="'all'"></app-tabela>
                    </div>
                </div>
    
                <!--<div class="card">
                  <div class="d-flex card-header">
                      <h5 class="mr-auto">Legenda da Situação do Indicadores</h5>
          </div>
          <div class="row p-2" footer>
              <div class="d-flex justify-content-center col-4">
                <div class="col-sm">
                  <i class="circle-red"></i>
                  Pendente
                </div>
                <div class="col-sm">
                  <i class="circle-blue"></i>
                  Fora do Prazo
                </div>
                </div>
          </div>                
              </div>-->
    
                <div class="card">
                    <div class="d-flex card-header">
                        <h5 class="mr-auto">
                          Legenda da Situação da Empresa no Monitoramento Fiscal
                      </h5>
                    </div>
                    <div class="row p-2" footer>
                        <div class="d-flex justify-content-center col-12">
                            <div class="col-sm">
                                <i class="circle-ligth-green"></i> Em Desenvolvimento
                            </div>
                            <div class="col-sm">
                                <i class="circle-ligth-blue"></i> Aguardando Aprovação
                            </div>
                            <div class="col-sm">
                                <i class="circle-yellow"></i> Não Aprovado
                            </div>
                            <div class="col-sm">
                                <i class="circle-purple"></i> A Homologar
                            </div>
    
                        </div>
                        <div class="d-flex justify-content-center p-t-15 col-12">
                            <div class="col-sm">
                                <i class="circle-green"></i> Ativo
                            </div>
                            <div class="col-sm">
                                <i class="circle-red"></i> Cancelado
                            </div>
                            <div class="col-sm">
                                <i class="circle-blue"></i> Finalizado
                            </div>
                            <div class="col-sm">
                                <i (click)="help()" class="feather icon-help-circle ml-3"></i>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="card" *ngIf="contributorId !=''">
                    <div class="d-flex card-header">
                        <h5 class="mr-auto">Filtrar</h5>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="formPesquisaEmpresa">
                            <div class="col-md-12 p-t-15">
                                <div class="row form-group">
                                    <div class=" col-sm-3">
                                        <label class="col-form-label" style="color:white">
                                            .
                                        </label>
                                        <select class="form-control form-control-sm" id="pesquisaEmpresa" [(ngModel)]="pesquisaEmpresa" formControlName="pesquisaEmpresa" name="pesquisaEmpresa">
                                            <option value="razao-social">
                                                Razão Social
                                            </option>
                                            <option value="nome-fantasia">
                                                Nome Fantasia
                                            </option>
                                            <option value="cpf">
                                                CPF
                                            </option>
                                            <option value="cnpj">
                                                CNPJ
                                            </option>
                                        </select>
                                        <div *ngIf="
                            formPesquisaEmpresa.controls.pesquisaEmpresa.invalid &&
                            (formPesquisaEmpresa.controls.pesquisaEmpresa.dirty ||
                            formPesquisaEmpresa.controls.pesquisaEmpresa.touched)
                            " class="invalid-feedback d-block">
                                            <div *ngIf="
                            formPesquisaEmpresa.controls.pesquisaEmpresa.errors
                            .required">
                                                O campo é obrigatório.
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-3">
                                        <label for="filter" class="col-form-label" style="margin-top:-2px;">
                                            St. Monitoramento Fiscal:
                                        </label>
                                        <select class="form-control form-control-sm" formControlName="situacaoMonitoramento">
                                            <option value="todos"> Todos </option>
                                            <option value="planejada">
                                                Planejada
                                            </option>
                                            <option value="designada">
                                                Designada
                                            </option>
                                            <option value="devolvida">
                                                Devolvida
                                            </option>
                                            <option value="execucao">
                                                Em Execução
                                            </option>
                                            <option value="vetada">
                                                Vetada
                                            </option>
                                            <option value="anulada">
                                                Anulada
                                            </option>
                                            <option value="vencida">
                                                Vencida
                                            </option>
                                            <option value="concluida">
                                                Concluída
                                            </option>
                                            <option value="pendente">
                                                Pendente
                                            </option>
                                            <option value="aberto">
                                                Em Aberto
                                            </option>
                                            <option value="homologada">
                                                Homologada
                                            </option>
                                        </select>
                                    </div>
    
                                    <div class="col-md-3" *ngIf="pesquisaEmpresa == 'cnpj'">
                                        <label class="col-form-label" style="color:white">
                                            .
                                        </label>
                                        <input type="text" class="form-control form-control-sm  ml-2" id="empresa" formControlName="doc" mask="00.000.000/0000-00" />
                                        <div *ngIf="
                            formPesquisaEmpresa.controls.doc.invalid &&
                            (formPesquisaEmpresa.controls.doc.dirty ||
                            formPesquisaEmpresa.controls.doc.touched)
                            " class="invalid-feedback d-block">
                                            <div *ngIf="
                            formPesquisaEmpresa.controls.doc.errors
                            .required
                          ">
                                                O campo é obrigatório.
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-3" *ngIf="pesquisaEmpresa == 'razao-social'">
                                        <label class="col-form-label" style="color:white">
                                            .
                                        </label>
                                        <input type="text" class="form-control form-control-sm ml-2" id="empresa" formControlName="razao" />
                                        <div *ngIf="
                            formPesquisaEmpresa.controls.razao.invalid &&
                            (formPesquisaEmpresa.controls.razao.dirty ||
                            formPesquisaEmpresa.controls.razao.touched)
                            " class="invalid-feedback d-block">
                                            <div *ngIf="
                            formPesquisaEmpresa.controls.razao.errors
                            .required
                            ">
                                                O campo é obrigatório.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3" *ngIf="pesquisaEmpresa == 'nome-fantasia'">
                                        <label class="col-form-label" style="color:white">
                                            .
                                        </label>
                                        <input type="text" class="form-control form-control-sm  ml-2" id="empresa" formControlName="nome" />
                                        <div *ngIf="
                            formPesquisaEmpresa.controls.nome.invalid &&
                            (formPesquisaEmpresa.controls.nome.dirty ||
                            formPesquisaEmpresa.controls.nome.touched)
                            " class="invalid-feedback d-block">
                                            <div *ngIf="
                            formPesquisaEmpresa.controls.nome.errors
                            .required
                          ">
                                                O campo é obrigatório.
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-3" *ngIf="pesquisaEmpresa == 'cpf'">
                                        <label class="col-form-label" style="color:white">
                                            .
                                        </label>
                                        <input type="text" class="form-control form-control-sm ml-2" id="empresa" formControlName="cpf" mask="000.000.000-00" />
                                        <div *ngIf="
                            formPesquisaEmpresa.controls.cpf.invalid &&
                            (formPesquisaEmpresa.controls.cpf.dirty ||
                            formPesquisaEmpresa.controls.cpf.touched)
                            " class="invalid-feedback d-block">
    
                                            <div *ngIf="
                            formPesquisaEmpresa.controls.cpf.errors
                            .required
                           ">
                                                O campo é obrigatório.
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-md-2">
                                        <label class="col-form-label" style="color:white">
                                            .
                                        </label>
                                        <button type="button" class="p-0 btn btn-success ml-2 form-control form-control-sm" (click)="validaEmpresa()">
                                            <span>Consultar</span>
                                        </button>
                                    </div>
    
                                </div>
                            </div>
                        </form>
    
                    </div>
                </div>
    
                <div class="card" *ngIf="contributorId !=''">
                    <div class="d-flex card-header">
                        <h5 class="mr-auto">{{contributorId}}</h5>
                    </div>
                    <div class="card-body">
                        <app-tabela [dados]="monitoramentoEmpresa.dados" [campos]="monitoramentoEmpresa.campos" [calcColumn]="'all'"></app-tabela>
                    </div>
                </div>
    
                <div class="card" *ngIf="contributorId !=''">
                    <div class="d-flex card-header">
                        <h5 class="mr-auto">
                  Legenda da Situação da Empresa no Monitoramento Fiscal
                </h5>
                    </div>
                    <div class="row p-2" footer>
                        <div class="d-flex justify-content-center col-12">
                            <div class="col-sm">
                                <i class="circle-planejada"></i> Planejada
                            </div>
                            <div class="col-sm">
                                <i class="circle-homologada"></i> Homologada
                            </div>
                            <div class="col-sm">
                                <i class="circle-pendente"></i> Pendente
                            </div>
                            <div class="col-sm">
                                <i class="circle-designada"></i> Designada
                            </div>
                            <div class="col-sm">
                                <i class="circle-vetada"></i> Vetada
                            </div>
                            <div class="col-sm">
                                <i class="circle-anulada"></i> Anulada
                            </div>
                            <div class="col-sm">
                                <i class="circle-devolvida"></i> Devolvida
                            </div>
    
                        </div>
                        <div class="d-flex justify-content-center col-12 p-t-15">
                            <div class="col-sm">
                                <i class="circle-emAberto"></i> Em aberto
                            </div>
                            <div class="col-sm">
                                <i class="circle-emExecucao"></i> Em execução
                            </div>
                            <div class="col-sm">
                                <i class="circle-vencida"></i> Vencida
                            </div>
                            <div class="col-sm">
                                <i class="circle-concluida"></i> Concluida
                            </div>
                            <div class="col-sm">
                                <i (click)="help2()" class="feather icon-help-circle ml-3"></i>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </app-conteudo>
    
    <app-modal id="comunicadoModal" titulo="Gerar Comunicado" tamanho="lg">
        <div class="container">
            <form [formGroup]="formComunicado" (ngSubmit)="enviarComunicado()">
                <div class="form-row">
                    <div class="form-group col-12 mt-2">
                        <label for="para">Para:</label>
                        <div class="row">
                            <div class="d-flex col-md-4">
                                <input type="text" class="form-control form-control-sm" id="para-matricula" placeholder="Matrícula" />
                            </div>
    
                            <div class="d-flex col-md-8">
                                <input type="text" class="form-control form-control-sm" id="para-nome" placeholder="Nome" />
                                <i (click)="pesquisarMatriculaNome()" class="feather icon-search input-group-text ml-2"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="assunto">Assunto:</label>
                        <div class="d-flex">
                            <input type="text" class="form-control form-control-sm" id="assunto" />
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12">
                        <label for="texto">Texto:</label>
                        <textarea class="form-control" id="textarea" rows="3" minlength="15" maxlength="255"></textarea>
                    </div>
                </div>
                <button type="submit" class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-0">
                    <span>Enviar</span>
                </button>
                <button type="button" (click)="fechaModalComunicado()" class="p-0 btn btn-secondary form-control col-sm-2 form-control-sm mt-3 float-right mb-2 mr-2">
                    <span>Cancelar</span>
                </button>
            </form>
        </div>
    </app-modal>
    
    <app-modal (close)="gerarComunicado()" id="matriculaNomeModal" titulo="Pesquisa Matrícula/Nome" tamanho="xg">
        <div class="container">
            <form [formGroup]="formMatriculaNome" (ngSubmit)="filtraMatriculaNome()">
                <div class="form-row align-items-end mt-2 mb-2">
                    <div class="form-group mb-0 col-4 mt-2">
                        <label for="matricula">Matrícula:</label>
                        <div class="d-flex">
                            <input type="text" class="form-control form-control-sm" id="matricula" />
                        </div>
                    </div>
    
                    <div class="form-group mb-0 col-4 mt-2">
                        <label for="nome">Nome:</label>
                        <div class="d-flex">
                            <input type="text" class="form-control form-control-sm" id="nome" />
                        </div>
                    </div>
                    <button type="submit" class="p-0 btn btn-success form-control col-sm-2 form-control-sm mb-0 ml-3">
                        <span>Pesquisar</span>
                    </button>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-tabela [dados]="matriculas.dados" [campos]="matriculas.campos"></app-tabela>
                    </div>
                </div>
                <button disabled type="button" class="p-0 btn btn-success form-control col-sm-2 form-control-sm mt-3 mb-0 mr-0 float-right">
                    <span>Selecionar</span>
                </button>
            </form>
        </div>
    </app-modal>