<div
    class="navbar pcoded-header navbar-expand-lg navbar-light"
    [ngClass]="{ 'navbar-collapsed': !openMenu }"
>
    <div class="m-header">
        <a class="mobile-menu" id="mobile-collapse" href="javascript:">
            <span></span>
        </a>

        <a routerLink="/">
            <app-logo></app-logo>
        </a>
    </div>

    <a class="mobile-menu" id="mobile-header" href="javascript:">
        <i class="feather icon-more-horizontal"></i>
    </a>

    <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto">
            <li>
                <img src="./assets/images/logo-tocantins.png" class="logo" />
            </li>

            <li>
                <a
                    href="javascript:"
                    class="full-screen"
                    onclick="javascript:toggleFullScreen()"
                >
                    <i class="feather icon-maximize"></i>
                </a>
            </li>

            <li class="nav-item dropdown">
                <a
                    class="dropdown-toggle"
                    href="javascript:"
                    data-toggle="dropdown"
                    >Opções</a
                >
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="javascript:">Opção 1</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="javascript:">Opção 2</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="javascript:">Opção 3</a>
                    </li>
                </ul>
            </li>
            <li class="nav-item">
                <div class="main-search">
                    <div class="input-group">
                        <input
                            type="text"
                            id="m-search"
                            class="form-control"
                            placeholder="Buscar por..."
                        />
                        <a
                            href="javascript:"
                            class="input-group-append search-close"
                        >
                            <i class="feather icon-x input-group-text"></i>
                        </a>
                        <span
                            class="input-group-append search-btn btn"
                        >
                            <i class="feather icon-search input-group-text"></i>
                        </span>
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <button class="tutoriais" type="button" (click)="abreModal('tutoriais')">Tutoriais</button>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
            <li>
                <div class="dropdown">
                    <a
                        class="dropdown-toggle"
                        href="javascript:"
                        data-toggle="dropdown"
                    >
                        <i class="icon feather icon-bell"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right notification">
                        <div class="noti-head">
                            <h6 class="d-inline-block m-b-0">Mensagens</h6>
                            <div class="float-right">
                                <a href="javascript:" class="m-r-10"
                                    >marcar como lidas</a
                                >
                                <a href="javascript:">limpar</a>
                            </div>
                        </div>
                        <!-- <ul class="noti-body">
                            <li class="n-title">
                                <p class="m-b-0">NOVAS</p>
                            </li>
                            <li class="notification">
                                <div class="media">
                                    <img
                                        class="img-radius"
                                        src="assets/images/user/avatar-1.jpg"
                                        alt="Generic placeholder image"
                                    />
                                    <div class="media-body">
                                        <p>
                                            <strong>John Doe</strong
                                            ><span class="n-time text-muted"
                                                ><i
                                                    class="icon feather icon-clock m-r-10"
                                                ></i
                                                >30 min</span
                                            >
                                        </p>
                                        <p>Novo ticket</p>
                                    </div>
                                </div>
                            </li>
                            <li class="n-title">
                                <p class="m-b-0">ANTERIORES</p>
                            </li>
                            <li class="notification">
                                <div class="media">
                                    <img
                                        class="img-radius"
                                        src="assets/images/user/avatar-2.jpg"
                                        alt="Generic placeholder image"
                                    />
                                    <div class="media-body">
                                        <p>
                                            <strong>Joseph William</strong
                                            ><span class="n-time text-muted"
                                                ><i
                                                    class="icon feather icon-clock m-r-10"
                                                ></i
                                                >30 min</span
                                            >
                                        </p>
                                        <p>Ticket antigo</p>
                                    </div>
                                </div>
                            </li>
                        </ul> -->
                        <div class="noti-footer">
                            <a href="javascript:">ver todos</a>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="dropdown drp-user">
                    <a href="javascript:" data-toggle="dropdown">
                        <div class="pro-head">
                            <img
                                src="assets/images/user/user-regular.svg"
                                class="img-radius"
                                alt="User-Profile-Image"
                                style="height: 35px; width: 35px; margin-top: -3px;"
                            />
                            <span
                                style="font-weight: bold; color: #000;  margin-left: 10px;"
                                >{{
                                    (user$ | async)?.name || 'Nome do usuário'
                                }}</span
                            >
                        </div>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-right profile-notification"
                    >
                        <div class="pro-head">
                            <img
                                src="assets/images/user/user-regular.svg"
                                class="img-radius"
                                alt="User-Profile-Image"
                            />
                            <span>{{
                                (user$ | async)?.name || 'Nome do usuário'
                            }}</span>
                            <a
                                (click)="logout()"
                                href="javascript:"
                                class="dud-logout"
                                title="Logout"
                            >
                                <i class="feather icon-log-out"></i>
                            </a>
                        </div>
                        <ul class="pro-body">
                            <li>
                                <a href="javascript:" class="dropdown-item">
                                    <i class="feather icon-settings"></i>
                                    Configurações
                                </a>
                            </li>

                            <li>
                                <a href="javascript:" class="dropdown-item" routerLink="/configuracao/editar-usuario/{{
                                    (user$ | async)?.id
                                }}">
                                    <i class="feather icon-user" ></i>
                                    Meu Perfil
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>


<app-modal id="tutoriais" titulo="Tutoriais" tamanho="pequeno" [confirmarFechamento]="true" (fecharModal)="fecharModal()">
    <p class="text-center mt-4">Selecione o vídeo que deseja assistir:</p>
    <div class="boxContainer d-flex justify-content-center flex-wrap">
        <ul class="configuracoesList d-flex justify-content-center p-0">
            <li class="configuracoesList_item d-flex flex-column justify-content-between align-items-center mr-4 mt-4">
                <span class="cardTitulo">Enviar Comunicação</span>
                <a class="btn-success form-control mt-4" href="https://sfera-ged.sefaz.to.gov.br/share/s/z1P1ZnZ8TLKkEDMX9vN-Yw" target="_blank">Acessar</a>
            </li>
            <li class="configuracoesList_item d-flex flex-column justify-content-between align-items-center mr-4 mt-4">
                <span class="cardTitulo">Como Aderir ao Refis</span>
                <a class="btn-success form-control mt-4" href="https://sfera-ged.sefaz.to.gov.br/share/s/WZfegsXHQIaWbXEaRvGwJw" target="_blank">Acessar</a>
            </li>
        </ul>
    </div>
    <div class="modal-footer p-0 m-2">
        <button 
            type="button" 
            class="p-0 btn btn-secondary form-control col-sm-2 form-control-sm mt-3 mb-1 mr-2 float-right" 
            (click)="fecharModal()"
        >
            <span>Desistir</span>
        </button>
    </div>
</app-modal>

