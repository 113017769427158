import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-duvidas-frequentes',
  templateUrl: './duvidas-frequentes.component.html',
  styleUrls: ['./duvidas-frequentes.component.scss']
})
export class DuvidasFrequentesComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
   
  }

  navegarParaAcesso(): void {
    this.router.navigate(['/acesso']);
  }

}
